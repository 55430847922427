/* Newsletter popup css */
.popup_wrapper {
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
  height: 100%;
  opacity: 0;
  position: fixed;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999999;
}
.newsletter_popup_inner {
  align-items: center;
  border-radius: 0;
  bottom: 0;
  box-shadow: 0 0 7px 1px #222222;
  color: #ffffff;
  display: flex;
  height: 400px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 800px;
  z-index: 9999999;
}
.subscribe_area {
  padding: 40px 150px;
  text-align: center;
  width: 100%;
}
.subscribe_area > h2 {
  color: #434343;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
}
.subscribe_area > p {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  text-align: center;
  line-height: 20px;
}
.subscribe-form-group{
  text-align:center;
}
.subscribe-form {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid #dddddd;
  border-radius: 0;
  box-shadow: none;
  font-size: 13px;
  height: 45px;
  margin: 0 auto;
  padding: 6px;
  width: 271px;
}
.subscribe-form:focus{
  box-shadow:none;
  border:1px solid #ebebeb;
}
.subscribe-btn {
  background: #ef6644 none repeat scroll 0 0;
  border: 0 none;
  border-radius: 0;
  color: #fff;
  font-size: 14px;
  line-height: 35px;
  margin-top: 20px;
  padding: 0 20px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.subscribe-btn:hover{
  background:#ef6644;
}
.popup_off {
  border-radius: 0;
  color: #ff4136;
  cursor: pointer;
  display: block;
  font-size: 30px;
  opacity: 1;
  padding: 5px 10px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: 5px;
}
.newsletter-btn {
  background: #ff4136 none repeat scroll 0 0;
  font-size: 16px;
  font-weight: 600;
  height: 45px;
  margin-top: 20px;
  padding: 5px 30px;
}
.subscribe-form::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: pink;
}
.subscribe-form::-moz-placeholder { /* Firefox 19+ */
  color: pink;
}
.subscribe-form:-ms-input-placeholder { /* IE 10+ */
  color: pink;
}
.subscribe-form:placeholder { /* Firefox 18- */
  color: pink;
}
.newsletter_popup_inner:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #000;
    content: "";
    opacity: .6;
    height: 100%;
}
.subscribe_area {
    z-index: 9;
}
@media (min-width: 768px) and (max-width: 991px) {
	.newsletter_popup_inner {
      width: 720px;
    }
}
@media (max-width: 767px) {
	.newsletter_popup_inner {
      height: 300px;
      width: 300px;
    }
  	.subscribe_area {
      padding: 20px;
      width: 100%;
    }  	
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .newsletter_popup_inner {
    height: 300px;
    width: 400px;
  }
}
/* End Newsletter popup css */
/* Top Notification Bar */
.top-notification-bar {
	background: #43cea2;
	background: -moz-linear-gradient(left, #43cea2 0%, #185b9d 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, #43cea2), color-stop(100%, #185b9d));
	background: -webkit-linear-gradient(left, #43cea2 0%, #185b9d 100%);
	background: -o-linear-gradient(left, #43cea2 0%, #185b9d 100%);
	background: -ms-linear-gradient(left, #43cea2 0%, #185b9d 100%);
	background: linear-gradient(to right, #43cea2 0%, #185b9d 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#43cea2', endColorstr='#185b9d', GradientType=1 );
	padding: 10px 0;
  	position: relative;
}
.top-notification-bar::before {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.notification-entry {
	overflow: hidden;
}
.notification-entry p {
  color: #ffffff;
  display: inline-block;
  font-family: "Karla",sans-serif;
  font-weight: 400;
  margin: 0;
  float: left;
  position: relative;
}

.notification-entry p a {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  color: #319da0;
  display: inline-block;
  font-family: "Karla",sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 35px;
  line-height: 24px;
  margin-left: 15px;
  padding: 5px 20px;
  text-transform: uppercase;
}
.notification-entry p a:hover {
  background-color: transparent;
  color: #ffffff;
  text-decoration: none;
}
.notification-close-btn {
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid #ffffff;
	border-radius: 50px;
	color: #ffffff;
	display: block;
	float: right;
	font-size: 18px;
	height: 25px;
	line-height: 24px;
	margin-top: 5px;
	position: relative;
	transition: all 0.3s ease 0s;
	width: 25px;
	z-index: 999;
	padding: 0;
	text-align: center;
}
.notification-close-btn:hover {
  background-color: #fff;
  color: #319da0;
}
.notification-entry p a {
  width: auto;
}
@media only screen and (max-width: 767px) {
.notification-entry p {
  padding: 0 30px;
}
.notification-entry p a {
  display: block;
  margin: 10px auto 0;
  width: 110px;
}
.notification-close-btn {
  position: absolute;
  right: 15px;
  top: 0;
}	

}

@media only screen and (max-width: 479px) {

}

/* End Top Notification Bar */


/* quick view */
.productModal .selector-wrapper > label {
  width: 15%;
}
.productModal .single-option-selector {
  border-radius: 0;
  height: 35px;
  padding: 7px;
  width: 50%;
}
.productModal .selector-wrapper {
  margin-bottom: 15px;
  margin-top: 15px;
}
.productModal .variants_selects {
  border-bottom: medium none rgba(0, 0, 0, 0);
  padding: 0 0 30px;
}
.productModal .wrapper.input-content > label {
  float: left;
  margin-bottom: 0;
  margin-right: 15px;
  margin-top: 5px;
}
.productModal .cart-plus-minus input {
  border-radius: 0;
  text-align: center;
  width: 60px;
}
.productModal .modal-product .product-images {
  width: 45%;
}
.productModal .modal-product .product-info {
  width: 55%;
}
.productModal .stock2 {
  border-bottom: medium none rgba(0, 0, 0, 0);
  padding: 0 0 30px;
}
.productModal button.close {
  opacity: 1;
}
.productModal .s-price-box .amount {
  color: #a6a6a6;
  font-size: 15px;
  text-decoration: line-through;
  font-weight: normal;
}
.productModal .selector-wrapper > label{
  display: none;
}
.productModal .selector-wrapper > label[for=product-select-qv-option-0], .productModal .selector-wrapper > label[for=product-select-qv-option-1],
.productModal .selector-wrapper > label[for=product-select-qv-option-2], .productModal .selector-wrapper > label[for=product-select-qv-option-3]
{
  display: inline-block;
}
.disabled-add-to-cart {
  cursor: not-allowed;
}

.quick-btn {
	background: #222 none repeat scroll 0 0;
	border: 0 none;
	box-shadow: 1px 0 1px rgba(0, 0, 0, 0.3);
	color: #fff;
	border-radius: 0;
	font-weight: 700;
	height: 44px;
	line-height: 44px;
	padding: 0 25px;
	text-transform: uppercase;
	width: auto;
	transition: 0.5s;
}
.quick-btn:hover {
	background: #e59285 none repeat scroll 0 0;
}

/* end quick view */

/*image icons*/

/*
.header__area .header__sidebar__right > li.shop_search > a {
  background: rgba(0, 0, 0, 0) url("search_black.png") no-repeat scroll 0 center;
}
.header__area .header__sidebar__right > li.shopcart > a {
  background: rgba(0, 0, 0, 0) url("cart.png") no-repeat scroll 0 center;
}
.header__area .header__sidebar__right > li.wishlist > a {
  background: rgba(0, 0, 0, 0) url("button-wishlist.png") no-repeat scroll 0 center;
}
.header__area .header__sidebar__right > li.setting__bar__icon > a {
	background: transparent url("icon_setting.png") no-repeat scroll left center;
}
.slide .slider__content a.shopbtn::after {
	background: rgba(0, 0, 0, 0) url("links_static8.png") repeat scroll 100% 50%;
}
*/
  
.slider_style_3 .slide .slider__content a.shopbtn::after {
	background: rgba(0, 0, 0, 0) url("links_static_white.png") repeat scroll 100% 50%;
}
.slider_style_3.slider--15 .slide .slider__content a:hover::after {
	background-position: 0 0;
}

/*
.is-sticky.header__area .header__sidebar__right > li.shop_search > a,
.oth-page.header__area .header__sidebar__right > li.shop_search > a {
  background: rgba(0, 0, 0, 0) url("search_white.png") no-repeat scroll 0 center;
}
.is-sticky.header__area .header__sidebar__right > li.wishlist > a,
.oth-page.header__area .header__sidebar__right > li.wishlist > a {
  background: rgba(0, 0, 0, 0) url("button-wishlist_white.png") no-repeat scroll 0 center;
}
.is-sticky.header__area .header__sidebar__right > li.shopcart > a,
.oth-page.header__area .header__sidebar__right > li.shopcart > a {
  background: rgba(0, 0, 0, 0) url("cart_white.png") no-repeat scroll 0 center;
}
.is-sticky.header__area .header__sidebar__right > li.setting__bar__icon > a,
.oth-page.header__area .header__sidebar__right > li.setting__bar__icon > a  {
  background: transparent url("icon_setting_white.png") no-repeat scroll left center;
}
*/

.list__view .content .cart__action li.wishlist a {
	background: white url("wishlist.png") no-repeat scroll -6% 0;
}
.list__view .content .cart__action li.quickview a {
	background: #ffffff url("search_new.png") no-repeat scroll -6% 0;
}


.slide .slider__content h2 {
	max-width: 620px;
}
.bg_nr_ccc {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.section__title h2 span {
	color: #e59285;
}

.best-seel-area .product .product__thumb .hot__box {
	left: 15px;
	top: 15px;
}

.best-seel-area .product.slick-center .product__thumb .hot__box {
	top: 25px;
}

.bradcaump-content li {
	display: inline;
}
.bradcaump-content li span {
	color: #e59285;
}
.bradcaump-content li a::after {
	content: "/";
	position: absolute;
	right: 6px;
	top: 0px;
}
.bradcaump-content li a {
	color: #fff;
	font-size: 14px;
	font-style: italic;
	font-weight: 400;
	padding-right: 20px;
	position: relative;
	text-transform: capitalize;
}


.product-sidebar-color li a {
  border: 1px solid #dadada;
  cursor: pointer;
  float: left;
  height: 20px;
  margin: 0 15px 15px 0;
  max-width: 90px;
  min-width: 30px;
  overflow: hidden;
  padding: 1px 2px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
}
.product-sidebar-color li a:hover:not(.disabled) {
  border: 1px solid #fff;
  outline: 2px solid #ff5216;
}
.wedget__categories.product-sidebar-color {
  float: left;
  margin: 0 0 15px;
  width: 100%;
}
.wedget__categories.poroduct--cat ul li.active a {
  color: #e59285;
}
.product-sidebar-color li.active a{
  border: 1px solid #fff;
  outline: 2px solid #ff5216;
}


.shop-grid .product:hover .product__content h4,.shop-grid .product:hover .product__content ul.prize {
	opacity: 0;
	visibility: hidden;
}

.shop__list__wrapper .shop__list button {
  border: 1px solid #ccc;
  color: #a3a3a3;
  font-size: 15px;
  height: 31px;
  line-height: 32px;
  margin-right: 8px;
  padding: 0 !important;
  text-align: center;
  transition: all 0.5s ease 0s;
  width: 31px;
}
.shop__list__wrapper .shop__list button.change-view--active, .shop__list__wrapper .shop__list button:hover {
  border-color: #2e2e2e;
  color: #2e2e2e;
}
.orderby__wrapper > label {
  margin-bottom: 0;
  margin-right: 10px;
}
.orderby__wrapper select {
	border-radius: 0;
	padding: 5px 15px;
	border: 1px solid #e1e1e1;
	color: #898888;
}


.list__view .content .cart__action li.quickview a:hover {
	background-position: 108% 0;
	border: 2px solid #e59285;
	background-color: #e59285;
}


/* Swatch */
.swatch .header {
  color: #606060;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 12px;
}
.swatch .swatch-element {
  float: left;
}
.swatch-element > input {
  display: none;
}
.swatch-element > label {
  color: #858484;
  display: block;
  float: left;
  font-family: "Poppins",sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-right: 20px;
}
.swatch-element input:checked + label {
  color: #e59285;
}
.swatch-element > label:hover {
  color: #e59285;
}
.swatch-element > label {
  display: block;
}
.product-size {
  margin-bottom: 0px;
}
.product-size .swatch{
  margin-bottom: 40px;
}
.swatch .swatch-element.color {
  float: left;
  margin-top: 3px;
}
.swatch-element.color > label {
  border: 1px solid #dadada;
  cursor: pointer;
  float: left;
  height: 20px;
  margin: 0 10px 5px 0;
  max-width: 90px;
  min-width: 30px;
  overflow: hidden;
  padding: 1px 2px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
}
.swatch-element.color input:checked + label, .swatch-element.color label:hover {
	border: 1px solid #fff;
	outline: 2px solid #e59285;
}
.pro__dtl__color .swatch {
  margin-bottom: 30px;
}
.product-color-label .swatch {
  margin-bottom: 15px;
}
/* End Swatch */




/* Quantity Button */
.box-tocart .js-qty, .box-tocart .ajaxcart__qty {
  margin-bottom: 0;
  margin-right: 20px;
}
.box-tocart .js-qty input, .ajaxcart__qty input {
  border: 1px solid #ebebeb;
  border-radius: 0;
  height: 40px;
  padding: 5px 10px;
}
.box-tocart .js-qty__adjust, .box-tocart .ajaxcart__qty-adjust {
  padding: 0;
}
/* End Quantity Button */
.product__info__main .box-tocart .addtocart__actions .tocart {
  background: #2e2e2e;
  display: block;
  transition:.3s
}
.product__info__main .product-addto-links a.email {
  background: transparent url("button-email.png") no-repeat scroll 0 0;
}
.product__info__main .product-addto-links a.wishlist {
	background: transparent url("single-button-wishlist.png") no-repeat scroll 0 0;
}

.product-color-label .selector-wrapper {
	display: none;
}

.wn__related__product .product:hover .product__content h4 {
	opacity: 0;
	visibility: hidden;
}

.description__attribute h2 {
	font-size: 22px;
	font-weight: normal;
	margin-bottom: 15px;
}

/*cart plus minus single product*/

.box-tocart .js-qty {
	max-width: 150px;
	margin: 0 10px;
}
.js-qty button.js-qty__adjust {
	border: none;
	background: none;
}

.product__info__main .box-tocart span.icon.icon-minus,
.product__info__main .box-tocart span.icon.icon-plus,
.product-quantity .js-qty .js-qty__adjust span.icon.icon-minus,
.product-quantity .js-qty .js-qty__adjust span.icon.icon-plus,
.product-quantity .js-qty .js-qty__adjust .visually-hidden {
	display: none;
}

.js-qty .js-qty__num {
	width: 50%;
	text-align: center;
}
.product__info__main .box-tocart span.visually-hidden {
	display: none;
}
.product__info__main .box-tocart span {
	margin: 0 10px 0 0;
}
.product__info__main .box-tocart span {
	margin: 0 10px 0 0;
}
.product-quantity .js-qty .js-qty__num {
	width: 30%;
}
.table-content table td.product-quantity input {
	padding: 0 10px;
}





.product__info__main .product-addto-links a:hover, .product__info__main .product-addto-links a.is-active {
	background-color: #e59285;
	background-position: 100% center;
	border-color: #e59285;
}
.product__info__main .product-addto-links a:hover, .product__info__main .product-addto-links a.is-active {
	background-color: #e59285;
	border-color: #e59285;
}

.product__info__main .price-box {
	display: flex;
}

.product__info__main .price-box del span {
	font-size: 20px;
	font-weight: normal;
	margin-left: 10px;
}
.spr-badge-caption {
	display: none;
}
.spr-starrating.spr-badge-starrating i {
	font-size: 13px;
	padding: 2px;
	color: #e59285;
}
.cras.product-info {
	padding-left: 0;
}
.productModal .modal-product .product-images {
	width: 50%;
}
.img_box_1 img {
	width: 100%;
}

.productModal .single-option-selector {
	height: 40px;
	border: 1px solid #ccc;
}
.productModal .cart-plus-minus input {
	height: 40px;
	border: 1px solid #ccc;
}
.last-cart.addtocart-btn {
	margin-top: 50px;
}
.modal-dialog.modal__container {
	max-width: 1100px;
}
.productModal .variants_selects {
	padding: 0 0 20px;
}
.last-cart.addtocart-btn {
	margin-top: 30px;
}



.fotorama__wrap .fotorama__arr.fotorama__arr--prev{background:url(fotorama.png) no-repeat}
.fotorama__wrap .fotorama__arr.fotorama__arr--next {
	background: url(fotorama.png) no-repeat;
	transform: rotate(180deg);
}



/*ajax popup*/
.ajax-popup .modal-body {
	padding: 30px 20px;
	position: relative;
}
.ajax-popup .modal-header .close {
	height: 25px;
	width: 25px;
	line-height: 23px;
	top: 20px;
	right: 10px;
	position: relative;
	z-index: 99999;
}
.ajax-popup .modal-body i {
	border: 1px solid;
	height: 20px;
	width: 20px;
	border-radius: 100%;
	line-height: 17px;
	margin-right: 5px;
}
.ajax-popup .modal-footer a {
	height: 35px;
	margin: auto;
	background: #444;
	width: 100%;
	line-height: 33px;
	color: #fff;
  border:1px solid #444
}

.ajax-popup .modal-footer a:hover {
	background: #e59285;
	border-color: #e59285
}
#modalAddToCart .modal-header {
	padding: 0 15px;
}


#CartSpecialInstructions {
	width: 100%;
	min-height: 100px;
	border: 1px solid #ccc;
}
.cart-note span {
	font-size: 18px;
	margin-bottom: 10px;
	display: block;
}
.cart-note {
	margin-top: 40px;
}

.cart__btn__list li input {
	background: #fff none repeat scroll 0 0;
	border-radius: 5px;
	display: block;
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	font-weight: 400;
	height: 50px;
	line-height: 50px;
	padding: 0 26px;
	text-transform: capitalize;
	transition: all 0.3s ease 0s;
  	border:none
}
.cart__btn__list li input:hover {
	background: #e59285 none repeat scroll 0 0;
	color: #fff;
}

.table-content thead {
	background: #f6f6f6;
}
.wn__sidebar .widget.recent_widget .recent-posts ul li .post-wrapper .thumb {
	width: 100px;
	flex: 0 0 100px;
}
.post-wrapper .content h4 {
	line-height: 16px;
}
.wn__sidebar .widget.recent_widget .recent-posts ul li .post-wrapper .content p {
	line-height: 20px;
}

.blog-details .comment_respond .comment__form .submite__btn button {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: 2px solid #333;
	color: #333;
	display: inline-block;
	font-size: 12px;
	font-weight: 700;
	line-height: 34px;
	padding: 2px 20px 0;
	text-shadow: none;
	text-transform: uppercase;
	transition: all 0.4s ease 0s;
}
.blog-details .comment_respond .comment__form .submite__btn button:hover {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border-color: #e59285;
	color: #e59285;
}

.comment-form .input__wrapper {
	margin-bottom: 20px;
}
.blog-details.content table tr td {
	border: medium none;
	width: 50% !important;
	padding: 0 5px;
}
.blog-details.content table {
	border: medium none;
	margin: 0 -5px;
}

.minicart-content-wrapper.item-empty-list, .item-multiple-item-count, .item-single-item-count {
	display: none;
}
.minicart-content-wrapper .items-total span span {
	float: left;
	margin-right: 5px;
}
.mini-cart-item li .item01 {
	margin-top: 20px;
}
.mini-cart-item li:first-child .item01 {
	margin-top: 0;
}

.minisearch .field__search button i {
	color: #797979;
	display: inline-block;
	font-size: 26px;
	height: 60px;
	left: 0;
	line-height: 60px;
	text-align: center;
	top: 0;
	width: 60px;
}
.minisearch .field__search button {
	background: none;
	border: none;
}
.page_error.section-padding--lg {
	padding: 120px 0 160px;
}
.error__inner .error__content .form__box input {
	width: 500px;
}

.meninmenu li.drop .megamenu.normal-dropdown.dropdown {
	display: block;
}


.meninmenu li.drop .megamenu.normal-dropdown a.item  {
	color: #333;
	display: block;
	font-size: 12px;
	line-height: 30px;
	font-weight: 400;
	padding: 0;
	text-transform: capitalize;
	transition: all 0.3s ease 0s;
}

.mainmenu__nav .meninmenu li.drop .megamenu.normal-dropdown a.item  {
	padding-left: 30px;
	padding-right: 20px;
}
.mainmenu__nav .meninmenu li.drop .megamenu.normal-dropdown a.item:hover {
	color: #e59285;
	padding-left: 35px;
}
.product .product__content .action .actions_inner .add_to_links li a.is-active {
	background: #e59285;
	color: #fff;
}

.product-tile-container.wishlist-tile-container {
	margin-bottom: 30px;
	padding: 0 15px;
	width: 25%;
}
.wishlist-grid.flex {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
}

.wishlist-grid .product .product__content .action {
	left: 15px;
	margin-bottom: 0;
}
.wishlist-hero--title {
	font-size: 24px;
	margin-bottom: 20px;
}
.mb--60 {
	margin-bottom: 60px;
}
.template-product .section-padding--lg {
	padding-bottom: 50px;
}
.blog-page .col-lg-12:first-child .blog__post {
	margin-top: 0;
}
.blog-details .comment_respond .comment__form .input__box.one--third {
	width: 50%;
}

.minicart-content-wrapper {
	max-height: 550px;
	overflow: auto;
}
.list__view__wrapper {
	margin-bottom: 20px;
}
.slider--15 .slide .slider__content h2 span {
	color: #e59285;
}
.contentbox > h3 {
	color: #e59285;
}
.product .product__thumb .hot__box {
	background: #e59285 none repeat scroll 0 0;
}
.product .product__thumb .hot__box::after {
	border-color: transparent transparent transparent #e59285;
}
.product.product__style--3 .product__content .prize li {
	color: #e59285;
}
.post__itam {
	box-shadow: 0 5px 15px rgba(85, 85, 85, 0.1);
}


/*new*/
.blog-page .blog__btn .shopbtn::after {
	background: none
}
.google__map {
	padding-top: 80px;
}
.wn__brand__area {
	border-top: 1px solid #eee;
}
.product-color-label {
	display: flex;
	padding: 20px 0 10px;
	flex-wrap: wrap;
}
.product-color-label .swatch {
	margin-bottom: 15px;
	margin-right: 15px;
}
.product__info__main .product__overview {
	padding: 20px 0 10px;
}

.product__info__main .box-tocart .js-qty span {
	margin: 0 5px;
}
.product__info__main .box-tocart .js-qty {
	max-width: 100px;
}
.product-addto-links {
	padding-left: 10px;
}
.product__info__main .product-share ul > span {
	margin-right: 10px;
	position: relative;
	top: -3px;
	font-weight: 700;
	text-transform: capitalize;
}
.posted-in {
	font-weight: 700;
}
.product .product__content .action .actions_inner .add_to_links li a:hover {
	background-color: #e59285;
}
.header__area .header__sidebar__right > li.shopcart > a span.product_qun {
	background: #e59285 none repeat scroll 0 0;
}
.list__view .content .cart__action li.wishlist .is-active {
	border: 2px solid #e59285;
	background-color: #e59285;
	background-position: 107% 0;
}


.product-addto-links.clearfix {
	position: relative;
}

.product__info__main .product-addto-links a.go-wishlist-class {
	font-size: 14px;
	position: absolute;
	left: 10px;
	background: #e59285;
	color: #fff;
	border-color: #e59285;
	display: none;
}

.product__info__main .product-addto-links .is-active+ a.go-wishlist-class {
	display: block;
}

.list__view__wrapper .list__view li a.go-wishlist-class {
	display: none;
}


.wedget__categories.poroduct--tag ul li.active a {
	background: #464646;
	color: #fff;
}



/*Shopify Newsletter Popup CSS*/

.shopify_newsletter_class .email {
	width: 70%;
	border-radius: 0;
	background: #fff;
	border: 1px solid #fff;
	height: 50px;
	padding: 10px;
}
.shopify_newsletter_class .button {
	width: 30%;
	background: #fff;
	border: 1px solid #fff;
	color: #888;
	line-height: 21px;
}
.shopify_newsletter_class .mc-form {
	display: flex;
}
.shopify-challenge__container {
	padding: 100px 0;
}
.form-message.form-message--success.form-success {
	color: #888;
}
.shopify_newsletter_class .button:hover {
	color: #ec552c;
}




/*Snow Fall CSS*/

.rel-area {
  position: relative;
}


@keyframes snow {
 0% {
  background-position:0 0,0 0,0 0
 }
 100% {
  background-position:500px 1000px,400px 400px,300px 300px
 }
}



.ice_effect {
 background-image:url(//cdn.shopify.com/s/files/1/0070/1107/4137/files/ice01.png?323),url(//cdn.shopify.com/s/files/1/0070/1107/4137/files/ice02.png?323);
 animation:snow 20s linear infinite;
}
.effect-body {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 0;
	background-color: rgba(0,0,0,0);
}



/* Compare css  */
.loading-modal {
  background: #fff url("loading.gif") center 14px no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 50%!important;
  bottom: auto;
  left: 50% !important;
  right: auto;
  width: 56px;
  height: 56px;
  margin-left: -28px;
  margin-top: -28px;
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-indent: -999em;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: block;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.close-modal {
  float: right;
  bottom: 0;
  position: absolute;
  right: 0;
}
.compare_modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: 0;

}
#compare-modal #table-compare th { font-weight:normal; }
#compare-modal .close-modal { bottom:auto;top:-15px;right:-7px;font-size:30px; }

#compare-modal .product_price { margin-bottom:10px; }

#compare-modal .product_price strong { margin-right:7px; }

#compare-modal table .btn { padding:10px 24px;font-size:13px; }

#compare-modal table button,#compare-modal table p { margin:0; }

#moda-compare .modal-content{
  border-radius: 0;
}

#table-compare th,#table-compare td { text-align:center; }
#table-compare td img { margin-bottom:5px; }
.compare_modal .th-compare th { text-align:center; }
.compare_modal h4 { text-align:center;margin:0;padding:20px; }
.compare-modal-close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0px;
  font-size: 25px;
  background: #000;
  color: #fff;
  padding: 5px 10px;
  line-height: 25px;
  z-index:999
}
#moda-compare .modal-dialog {
  position: relative;
  width: auto;
  margin: 30px auto;
  max-width: 65%;
}
.compare_modal h4 {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 17px;
  font-weight: 700;
  font-style: normal;
  display: inline-block;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
#compare-modal .modal-header {
  justify-content: center;
  position: relative;
}
#compare-modal th button,#compare-modal tr td {
  text-align: center;
  float: inherit;
}
#compare-modal .product-price strong {
  margin-right: 7px;
}
#compare-modal td a:hover, #compare-modal  .product-price,#compare-modal .modal-close:hover .fa.fa-times-circle {
  color: #d82e2e;
}
#compare-modal td a {
  text-transform: uppercase;
  font-size: 12px;
  color: #727272;
  font-weight: normal;
}
#compare-modal .product-price {
  margin-bottom: 5px;
  margin-top: 5px;
}
#compare-modal .modal-close .fa.fa-times-circle {
  transition: all 0.3s ease-out 0s;
}
#compare-modal th button, #compare-modal tr td {
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
}
#compare-modal .table-hover tbody tr:hover {
  background-color: rgba(0,0,0,.03);
}
#compare-modal .table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6;
}
/* End Compare CSS */

.header__area.header__absolute.sticky__header.header_common_main_class {
	background: transparent;
}


.sidebar-price-filter {
  display: flex;
  justify-content: space-between;
}
.sidebar-price-filter input {
  width: 70px;
  padding: 10px 5px;
  background: transparent;
  border: 1px solid #e5e5e5;
}
.sidebar-list-style .theme-default-button {
    margin: 20px 0 0;
}
.sidebar-price-filter label {
    font-size: 14px;
}
.sidebar-list-style ul li label {
    cursor: pointer;
}
.storefront-filter .filter-type-select ul li {
    font-size: 14px;
    cursor: pointer;
    line-height: 35px;
}
.filter-type-select ul li label {
    cursor: pointer;
    color: #333;
}
.filter-type-select ul li label:hover {
    color: #e59285;
}
.filter-type-select ul li input {
    margin: 0 5px 0 0;
      height: auto;
      width: auto;
}
.theme-default-button {
  background-color: #000000;
  border: medium none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 24px;
  margin-bottom: 0;
  padding: 8px 25px;
  border-radius: 0;
  text-align: center;
}
.theme-default-button:hover {
  background-color: #e59285;
  color: #fff;
}

/* Start Accordion */
.accordion summary {
  display: flex;
  position: relative;
  line-height: 1;
  padding: 10px 0;
  cursor: pointer;
}
.accordion .summary__title {
  display: flex;
  flex: 1;
}
.accordion + .accordion {
  margin-top: 0;
  border-top: none;
}
.accordion {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.accordion__title {
  display: inline-block;
  max-width: calc(100% - 6rem);
  margin: 0;
  word-break: break-word;
  line-height: 22px;
  font-size: 17px;
  color: #242424;
  font-weight: 500;
}
.accordion .icon-accordion {
  align-self: center;
  min-width: 1.6rem;
  margin-right: 1rem;
  fill: red;
}
.accordion details[open] > summary .icon-caret {
  transform: rotate(180deg);
}
.accordion__content {
    margin-bottom: 1.5rem;
    word-break: break-word;
    padding: 0 15px;
}
.accordion__content img {
  max-width: 100%;
}
summary .icon-caret {
    position: absolute;
    height: .5rem;
    right: 5px;
    top: calc(50% - .25rem);
}
.accordion__content h2 {
    font-size: 20px;
}
.accordion__content ul {
    list-style: inside;
    margin-bottom: 12px;
}
/* End Accordion */
.product__info__main h1 {
  margin: 0 0 10px;
}
.product__info__main .product-reviews-summary .rating-summary {
  margin-right: 0;
}
.product__info__main .product-reviews-summary {
  padding: 0;
  margin: 0 0 15px;
}
.product__info__main .price-box {
  margin: 0;
}
.product__info__main .product-price {
  margin: 0 0 20px;
}
.product-info-stock-sku.d-flex {
  margin: 0 0 20px;
}
.product-color-label {
  padding: 0 0 10px;
}
.product-meta {
  margin: 0 0 15px;
}
.product__info__main .product-share {
  margin: 0 0 15px;
}
small.unit_price_box.caption.hidden {
    display: none;
}
small.unit_price_box.caption {
    font-size: 13px;
    font-weight: 600;
    margin-top: 0;
    display: block;
}


